import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 地址
export function getAddressList(data) {
  return request({
    url: '/member/address',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchAddress(data) {
  return request({
    url: '/member/defaddress',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createAddress(data) {
  return request({
    url: '/member/addr/save',
    method: 'post',
    data: param2Obj(data)
  })
}

export function uploadAddress(data) {
  return request({
    url: '/member/addr/save',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteAddress(data) {
  return request({
    url: '/member/addr/delete',
    method: 'post',
    data: param2Obj(data)
  })
}