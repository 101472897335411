<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mhome-head">
				<div class="prev" @click="$router.back()"><i class="el-icon-arrow-left"></i></div>
				<div class="action" v-if="isEdit"><span @click="handleDelete()">{{$t('mobile.actions.delete')}}</span></div>
			</div>
			<div class="mhome-main">
				<el-form :model="addressInfo" :rules="addressRules" ref="addressInfo">
					<el-form-item :label="$t('mobile.formItem.consignee')" prop="consignee">
						<el-input v-model="addressInfo.consignee" maxlength="20" :placeholder="$t('mobile.placeholder.name')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('mobile.formItem.mobilePhone')" prop="phone_mob">
						<el-input v-model="addressInfo.phone_mob" maxlength="20" :placeholder="$t('mobile.placeholder.mobilePhone')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('mobile.formItem.location')" prop="region_id2">
						<div class="el-input el-input--suffix" @click="handlePopOpen">
							<div class="el-input__inner">
								<span v-if="regionIds.length>0">{{onAddressName}}</span>
								<span style="color: #CCCCCC;" v-else>{{$t('mobile.address.region')}}</span>
							</div>
							<span class="el-input__suffix">
								<span class="el-input__suffix-inner">
									<i class="el-icon-arrow-right el-input__icon"></i>
								</span>
							</span>
						</div>
					</el-form-item>
					<el-form-item :label="$t('mobile.formItem.fullAddress')" prop="address">
						<el-input type="textarea" :rows="3" maxlength="120" v-model="addressInfo.address" :placeholder="$t('mobile.placeholder.fullAddress')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('mobile.formItem.defaultAddress')">
						<div style="text-align: right;">
							<el-switch
								v-model="addressInfo.is_default"
								active-color="#ff5000"
								:active-value="1"
								:inactive-value="0">
							</el-switch>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<div class="mfooter-nav">
				<div class="btn" @click="handleSubmit">{{$t('mobile.actions.saveGap')}}</div>
			</div>
			<!-- 弹窗-地址列表 start -->
			<div class="mpop" :class="{'mpop-show':isPopShow}">
				<div class="mpop-bg" @click="handlePopClose"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{$t('mobile.address.location')}}</div>
					<div class="mpop-address">
						<div class="maddress-head" v-if="regionIds.length>0">
							<div class="item" v-for="(item,index) in regionIds" :key="index" :class="{active:checkIndex==index}" @click="handleAddressChange(item,index)">
								<div class="item-label">{{onRegionName(item,index)}}</div>
								<div class="item-block"><i class="el-icon-arrow-right"></i></div>
							</div>
						</div>
						<div class="mpop-main" ref="scrollbar">
							<div class="maddress-main">
								<div class="maddress-title">
									<template v-if="checkIndex==1">{{$t('mobile.address.city')}}</template>
									<template v-if="checkIndex==2">{{$t('mobile.address.area')}}</template>
									<template v-else>{{$t('mobile.address.province')}}</template>
								</div>
								<div class="item" v-for="(item,index) in regionList[checkIndex]" :key="index" @click="handleRegionChange(item)" :class="{active:regionIds.includes(item.region_id)}">
									<div class="item-label">{{item.region_name}}</div>
									<div class="item-block" v-if="regionIds.includes(item.region_id)"><i class="el-icon-check"></i></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 弹窗-订单备注 end -->
		</div>
	</div>
</template>

<script>
	import { fetchRegion } from '@/api/user';
	import { fetchAddress, createAddress, updateAddress, deleteAddress } from '@/api/mobile/address';
	export default {
		data() {
			var validateConsignee = (rule, value, callback)=>{
				if(value === ''){
					callback(new Error(this.$t('mobile.validate.consignee')));
				}else {
					callback();
				}
			}
			var validatePhone = (rule, value, callback)=>{
				let string = /^1[3-9]\d{9}$/
				if(value==='' || !string.test(value)){
					callback(new Error(this.$t('mobile.validate.mobilePhone')));
				}else {
					callback();
				}
			}
			var validateRegion = (rule, value, callback)=>{
				if(parseInt(value)>0){
					callback();
				}else {
					callback(new Error(this.$t('mobile.validate.location')));
				}
			}
			var validateAddress = (rule, value, callback)=>{
				if(value === ''){
					callback(new Error(this.$t('mobile.validate.fullAddress')));
				}else {
					callback();
				}
			}
			return {
				addressInfo: {
					consignee: '',
					phone_mob: '',
					address: '',
					region_name: '',
					is_default: 1,
					region_id0: 0,
					region_id1: 0,
					region_id2: 0,
					region_id3: 0,
				},
				addressRules: {
					consignee: [
						{ validator: validateConsignee, trigger: 'blur' }
					],
					phone_mob: [
						{ validator: validatePhone, trigger: 'blur' }
					],
					region_id2: [
						{ validator: validateRegion, trigger: 'blur' }
					],
					address: [
						{ validator: validateAddress, trigger: 'blur' }
					]
				},
				regionList: [],
				regionIds: [],
				checkIndex: 0,
				isPopShow: false,
				isEdit: false
			}
		},
		computed: {
			onAddressName:function(){
				let region_name = '';
				for(let i=0;i<4;i++){
					if(this.regionList[i]){
						let region_list = JSON.parse(JSON.stringify(this.regionList[i]));
						region_list.forEach(item=>{
							if(this.regionIds[i]===item.region_id){
								region_name+=item.region_name;
							}
						})
					}
				}
				if(region_name){
					this.$set(this.addressInfo,'region_name',region_name);
				}
				return region_name;
			}
		},
		mounted() {
			if(this.$route.query.edit_id){
				this.getAddressInfo();
				this.isEdit = true
			}else {
				this.getRegionList();
			}
		},
		methods: {
			getRegionList(pid=45056){
				let param = {
					parent_id: pid,
					uuid: this.$store.getters.uuid
				}
				fetchRegion(param).then(response => {
					if(pid==45056){
						this.regionList.push(response.data.list);
					}else {
						if(response.data.list.length>0){
							this.checkIndex++;
							this.regionIds.push(0);
							this.regionList.splice(this.checkIndex);
							this.regionList.push(response.data.list);
						}else {
							this.isPopShow = false;
						}
						if(this.isPopShow){
							this.$refs.scrollbar.scrollTop = 0;
						}
					}
				})
			},
			initRegionList(pid=45056){
				return new Promise((resolve,reject)=>{
					fetchRegion({parent_id: pid}).then(response => {
						resolve(response.data.list);
					}).catch(error => {
						reject(error)
					})
				})
			},
			getAddressInfo(){
				let loading = this.$loading();
				let param = {
					addr_id: this.$route.query.edit_id,
					uuid: this.$store.getters.uuid
				}
				fetchAddress(param).then(response => {
					loading.close();
					this.addressInfo = response.data || {};
					let promiseArr = [this.initRegionList()];
					let regionIds = [];
					this.regionList.splice(1);
					for(let i=0;i<4;i++){
						if(this.addressInfo['region_id' + i] > 0){
							regionIds.push(this.addressInfo['region_id' + i]);
							promiseArr.push(this.initRegionList(this.addressInfo['region_id' + i]));
						}
					}
					Promise.all(promiseArr).then(object=>{
						object.forEach(item=>{
							if(item.length>0){
								this.regionList.push(item);
							}
						})
					})
					this.regionIds = regionIds;
				}).catch(() => {
					loading.close();
				})
			},
			handlePopOpen(){
				this.isPopShow = true;
			},
			handlePopClose(){
				this.isPopShow = false;
			},
			onRegionName(id,index){
				let region_name = this.$t('mobile.address.select');
				if(this.regionList[index]){
					let region_list = JSON.parse(JSON.stringify(this.regionList[index]));
					switch(index){
						case 1:
						region_name = this.$t('mobile.address.city');
						break;
						case 2:
						region_name = this.$t('mobile.address.selectArea');
						break;
					}
					region_list.forEach(item=>{
						if(id===item.region_id){
							region_name = item.region_name;
						}
					})
				}
				return region_name;
			},
			handleAddressChange(id,index){
				this.checkIndex = index;
			},
			handleRegionChange(item){
				this.regionIds.splice(this.checkIndex);
				this.regionIds.push(item.region_id);
				this.getRegionList(item.region_id);
			},
			handleSubmit(){
				this.regionIds.forEach((item,obj)=>{
					this.addressInfo['region_id'+obj] = item;
				})
				let loading = this.$loading();
				this.$refs.addressInfo.validate((valid) => {
					if(valid){
						createAddress(this.addressInfo).then(() => {
							loading.close();
							let query = JSON.parse(JSON.stringify(this.$route.query));
							delete query.edit_id;
							this.$router.push({
								name: 'mOrderAddress',
								query: query
							})
						}).catch(() => {
							loading.close();
						})
					}else {
						loading.close();
						return false
					}
				})
			},
			handleDelete() {
				this.$confirm(this.$t('mobile.confirm.deleteAddress'),'',{
					confirmButtonText: this.$t('mobile.actions.confirmDelete'),
					cancelButtonText: this.$t('mobile.actions.cancel'),
					center: true,
					customClass: 'wap-message-confirm',
				}).then(() => {
					deleteAddress({addr_id: this.addressInfo.addr_id}).then(() => {
						this.$router.push({ name: 'mAddressList' })
					})
				}).catch(() => {
					this.$message({
						message: this.$t('mobile.cancelMsg.delete'),
						customClass: 'wap-message',
						type: 'info'
					});
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mhome-wrap {
		background-color: #FFFFFF;
	}
	.mhome-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 1.28rem;
		line-height: 1.28rem;
		background-color: #FFFFFF;
		border-bottom: 1px solid #E5E5E5;
		.prev {
			width: 1.28rem;
			height: 1.28rem;
			line-height: 1.28rem;
			font-size: 0.64rem;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			color: #777777;
		}
		.action {
			color: #ff5000;
			font-size: 0.384rem;
			margin-right: 0.384rem;
		}
	}
	.mhome-main {
		padding: 0.384rem;
		::v-deep .el-form {
			.el-form-item {
				display: flex;
				margin-bottom: 0.512rem;
				.el-form-item__label {
					min-width: 2.24rem;
					padding-right: 0.32rem;
					text-align: left;
					font-size: 0.416rem;
					line-height: 1.28rem;
				}
				.el-form-item__content {
					flex: 1;
					width: 0;
					line-height: 1.28rem;
				}
				.el-form-item__error {
					padding-top: 0.064rem;
					font-size: 0.352rem;
				}
			}
		}
		::v-deep .el-input {
			font-size: 0.416rem;
			display: block;
			.el-input__inner {
				vertical-align: top;
				height: 1.28rem;
				line-height: 1.28rem;
				border-radius: 0.256rem;
				padding: 0.128rem 0.96rem 0.128rem 0.384rem;
				span {
					// color: #CCCCCC;
					display: inline-block;
					vertical-align: top;
					line-height: 1.024rem;
				}
			}
			.el-input__icon {
				width: 0.8rem;
				line-height: 1.28rem;
			}
		}
		::v-deep .el-input.is-disabled {
			.el-input__inner {
				color: #606266;
				border-color: #DCDFE6;
				background-color: #FFFFFF;
			}
		}
		::v-deep .el-textarea {
			display: block;
			.el-textarea__inner {
				font-size: 0.416rem;
				resize: none;
				border-radius: 0.256rem;
				padding: 0.128rem 0.384rem;
			}
		}
		::v-deep .el-switch {
			height: 0.768rem;
			line-height: 0.768rem;
			.el-switch__core {
				height: 0.768rem;
				border-radius: 0.384rem;
				width: 1.6rem!important;
				border: none;
			}
			.el-switch__core:after {
				top: 0.064rem;
				left: 0.064rem;
				width: 0.64rem;
				height: 0.64rem;
			}
		}
		::v-deep .el-switch.is-checked {
			.el-switch__core:after {
				left: 100%;
				margin-left: -0.704rem;
			}
		}
	}
	.mfooter-nav {
		padding: 0.192rem 0.768rem;
	
		.btn {
			height: 1.28rem;
			line-height: 1.28rem;
			text-align: center;
			font-size: 0.48rem;
			color: #FFFFFF;
			border-radius: 0.64rem;
			background: linear-gradient(90deg, #ee7e32, #f25528);
		}
	}
	.mpop-address {
		height: 12.8rem;
		display: flex;
		flex-flow: column;
		.maddress-head {
			padding: 0 0.384rem;
			border-bottom: 1px solid #E5E5E5;
		}
		.mpop-main {
			flex: 1;
			height: 0;
			overflow-y: auto;
			.maddress-main {
				padding: 0 0.384rem;
			}
			.maddress-title {
				font-size: 0.48rem;
				font-weight: bold;
				padding: 0.192rem 0;
			}
		}
		.item {
			height: 1.152rem;
			line-height: 1.152rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.item-label {
				font-size: 0.416rem;
			}
			.item-block {
				font-size: 0.416rem;
			}
			.el-icon-arrow-right {
				color: #999999;
			}
			.el-icon-check {
				color: #FF5000;
			}
		}
		.item.active {
			.item-label {
				color: #FF5000;
			}
		}
	}
</style>
